import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngIDODevelopment = loadable(() => import("../components/IDODevelopment/english.js"));
const ArabicIDODevelopment = loadable(() => import("../components/IDODevelopment/arabic.js"));
const ChineseIDODevelopment = loadable(() => import("../components/IDODevelopment/chinese.js"));

const schema = {
  "@context": "http://schema.org/",
  "@graph": [
    {
      "@type": "product",
      "image": "https://softtik.com/images/nft-collection/invest-section-13.webp",
      "name": "Top IDO Development Company",
      "description": "Softtik is the leading IDO development company offering end-to-end IDO solutions to raise funds for many new startups and businesses.",
      "brand": "Softtik Technologies",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "reviewCount": "119"
      }
    },
    {
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is an IDO?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>IDO is a fundraising technique in which projects are listed on the decentralized liquidity exchanges. IDOs are considered to be the successors of fundraising techniques like ICO, IEO, etc.</p>"
        }
      }, {
        "@type": "Question",
        "name": "What is the difference between ICO and IDO?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>ICO and IDO are fundraising techniques, but ICO is not regulated, while an IDO project must vet before raising funds. In ICO, the token sale is conducted before coin listing on the exchange, but in IDO, tokens are listed on a decentralized exchange before purchase.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How much does an IDO cost?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>The cost of developing an IDO platform depends on your requirements. Reach out to us at <b>Softtik Technologies</b>, and submit your requirements list, and we will let you know the development cost of the IDO platform.</p>"
        }
      }
      ]
    }
  ]
}

export class IDODevelopment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: "English"
    }
  };

  async componentDidMount() {
    let region = localStorage.getItem('region');

    if (region == 'ae') this.setState({ lang: "Arabic" });
    else if (region == 'cn') this.setState({ lang: "China" });
    else this.setState({ lang: "English" });
  };

  render() {
    let { lang } = this.state;
    return (
      <>
        <InnerLayout header='Main' fallback={<Loader />}>
          <Head
            type="Website"
            fallback={<Loader />}
            schemaMarkup={schema}
            path="/ido-development-services/"
            title="IDO Development Company - Softtik Technologies"
            thumbnail="https://softtik.com/images/nft-collection/invest-section-13.webp"
            description="Softtik is the leading IDO development company offering end-to-end IDO solutions to raise funds for many new startups and businesses. "
          />
          <main>
            <div id="wrapper">
              <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                {lang == "Arabic"
                  ? <ArabicIDODevelopment />
                  : lang == "China"
                    ? <ChineseIDODevelopment />
                    : <EngIDODevelopment />
                }
              </div>
            </div>
          </main>
        </InnerLayout >
      </>
    )
  };
};

export default IDODevelopment;